import {CopyToClipboard} from "react-copy-to-clipboard/src";
import React, {useState} from "react";
import ErrorToast from "../components/ErrorToast";
import {HandThumbsDown, HandThumbsDownFill, HandThumbsUp, HandThumbsUpFill} from "react-bootstrap-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import FeedbackToast from "../components/FeedbackToast";
import KnowledgeBlockCard from "../components/KnowledgeBlockCard";

const ProgramDescriptions = [
    {name: "Mitgliederbuchhaltung", assistant_id: "asst_7MS5sBbJZAzz1rV3saHzatwo"},
    {name: "Bestandnehmerbuchhaltung", assistant_id: "asst_h908jNQ1dHEJL5MITyZZzolt"}
]

const Assistant = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isMessageReceived, setIsMessageReceived] = useState(false);
    const [showDivider, setShowDivider] = useState(false);

    const [textareaValue, setTextareaValue] = useState('');
    const [assistantValue, setAssistantValue] = useState('');

    const [activeTab, setActiveTab] = useState(ProgramDescriptions[0]);

    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [showFeedbackToast, setShowFeedbackToast] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const [selectedButton, setSelectedButton] = useState(null);

    const [knowledgeBlocks, setKnowledgeBlocks] = useState([]);

    const handleClick = (button) => {
        setSelectedButton(button);

        if(button === 'like') {
            setFeedbackMessage('Dank für Ihr positives Feedback.');
        } else {
            setFeedbackMessage('Danke für Ihr Feedback, wir schauen es uns an!');
        }
        setShowFeedbackToast(true);
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        resetBot();
    };

    const sendMessage = () => {
        if(textareaValue === '') return;

        setIsLoading(true);
        setShowDivider(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ prompt: textareaValue, assistant_id: activeTab.assistant_id })
        };
        fetch('https://cpiv2.hammerdigital.at/assistant-service/assistant-query', requestOptions)
            .then(response => {
                if (!response.ok) {
                    resetBot();
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setAssistantValue(data.response_text);

                return fetch(`https://cpiv2.hammerdigital.at/api/knowledge/cs-search/3?searchString=${encodeURIComponent(data.response_text)}`, {
                    method: 'GET'
                });
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const updatedKnowledgeBlocks = data.map(item => ({ name: item.knowledge }));
                setKnowledgeBlocks(updatedKnowledgeBlocks);
                setIsLoading(false);
                setIsMessageReceived(true);
            })
            .catch(error => {
                console.error('Error:', error);
                setErrorMessage('Verbindungsfehler');
                setShowToast(true);
                resetBot();
            });
    };

    const resetBot = () => {
        setShowDivider(false);
        setIsLoading(false);
        setIsMessageReceived(false);
        setTextareaValue('');
        setSelectedButton(null);
    }

    return (
        <div>
            <ul className="nav nav-tabs pt-3 px-5">
                {ProgramDescriptions.map((program) => (
                    <li className="nav-item" key={program.name}>
                        <a
                            className={`nav-link ${activeTab.name === program.name ? 'active' : ''}`}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleTabClick(program); // Pass the entire program object
                            }}
                        >
                            {program.name}
                        </a>
                    </li>
                ))}
            </ul>
            <div className="d-flex justify-content-center align-items-start mt-5">
                <div className="w-100" style={{maxWidth: '800px'}}>
                    <div className="d-flex align-items-center gap-2 mb-2">
                        <h5 className="text-left">domizil+
                            Rechnungswesen</h5>
                        <h5><strong>{activeTab.name}</strong></h5>
                    </div>
                    <div className="form-group position-relative">
                      <textarea
                          className="form-control p-3"
                          id="messageTextarea"
                          rows="6"
                          placeholder={`Ihr Frage`}
                          disabled={showDivider}
                          value={textareaValue}
                          onChange={(e) => setTextareaValue(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary mt-2" onClick={sendMessage}
                                disabled={showDivider || textareaValue === ''}>
                            Antwort suchen
                        </button>
                    </div>
                    {showDivider && <hr/>} {}

                    {isLoading && (
                        <div className="mt-3 position-relative">
                            <div className="progress" style={{height: '25px'}}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated"
                                     role="progressbar"
                                     style={{width: '100%'}}
                                     aria-valuenow="100"
                                     aria-valuemin="0"
                                     aria-valuemax="100">
                                    <strong>Der domizil+ Support BOT versucht für Sie die bestmögliche Antwort zu
                                        finden.</strong>
                                </div>
                            </div>
                        </div>
                    )}
                    {isMessageReceived && (
                        <div className="form-group position-relative mt-4">

                            <textarea
                                className="form-control no-focus-shadow p-3"
                                id="responseTextarea"
                                rows="10"
                                value={assistantValue}
                                readOnly
                            ></textarea>
                            <div className="d-flex justify-content-between align-content-center mt-2">
                                <div>
                                    <button className="btn btn-primary mx-2" onClick={resetBot}>
                                        Neue Anfrage
                                    </button>
                                    <CopyToClipboard text={assistantValue}>
                                        <button className="btn btn-outline-primary">
                                            Text kopieren
                                        </button>
                                    </CopyToClipboard>
                                </div>
                                <div>
                                    <div>
                                        <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => {
                                                if (selectedButton == null) {
                                                    return <Tooltip {...props}>Gute Antwort</Tooltip>;
                                                }
                                                return <span></span>;
                                            }}
                                            placement="bottom"
                                        >
                                            <button
                                                type="button"
                                                className={`btn p-1 btn-link ${selectedButton === 'like' ? 'text-primary' : ''}`}
                                                onClick={() => handleClick('like')}
                                                disabled={selectedButton !== null}
                                            >
                                                {selectedButton === 'like' ? (
                                                    <HandThumbsUpFill color="green" size={24} />
                                                ) : (
                                                    <HandThumbsUp color="green" size={24} />
                                                )}
                                            </button>
                                        </OverlayTrigger>


                                        <OverlayTrigger
                                            delay={{hide: 450, show: 300}}
                                            overlay={(props) => {
                                                if (selectedButton == null) {
                                                    return <Tooltip {...props}>Schlechte Antwort</Tooltip>;
                                                }
                                                return <span></span>;
                                            }}
                                            placement="bottom"
                                        >
                                            <button
                                                type="button"
                                                className={`btn p-1 btn-link ${selectedButton === 'dislike' ? 'text-primary' : ''}`}
                                                onClick={() => handleClick('dislike')}
                                                disabled={selectedButton !== null} // Disable if 'like' is selected
                                            >
                                                {selectedButton === 'dislike' ?
                                                    <HandThumbsDownFill size={24}/>
                                                    : <HandThumbsDown size={24}/>
                                                }
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showDivider && <hr/>} {}
                </div>
            </div>
            {isMessageReceived && (
                <div className="d-flex flex-column  align-items-center">
                    <h5>Referenziertes Wissen</h5>
                    <div className="d-flex justify-content-around align-items-center mt-3" style={{ flexWrap: 'wrap', gap: '20px' }}>
                        {knowledgeBlocks.map(knowledgeBlock => (
                            <KnowledgeBlockCard key={knowledgeBlock.name} knowledgeBlock={knowledgeBlock} />
                        ))}
                    </div>
                </div>
                )};
            <ErrorToast show={showToast} message={errorMessage} onClose={() => setShowToast(false)}/>
            <FeedbackToast show={showFeedbackToast} feedbackType={selectedButton} message={feedbackMessage} onClose={() => setShowFeedbackToast(false)}/>
        </div>
    );
}

export default Assistant;